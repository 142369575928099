require('./bootstrap');
window.$ = window.jQuery = require('jquery');

$(document).ready(function(event) {
    let top_header = $(".carousel .fill");

    $(window).scroll(function() {
        let st = $(this).scrollTop();
        top_header.css({
            "background-position": "center calc(50% + " + st * 0.8 + "px)"
        });
    });
});
